import React from 'react';
import {useServiceSliderData, useIconCardsData, useTestimonialSliderData} from '../shared/Hooks/AboutCompany';
import Img from 'gatsby-image';
import {useBackgroundImage} from '../shared/Hooks/Background';

export const useData = () => {
  const slides = useServiceSliderData();
  const cards = useIconCardsData();
  const testimonials = useTestimonialSliderData();
  const backgroundImgs = useBackgroundImage();

  return {
    title: 'Kim jesteśmy',
    header: {
      parallaxText: (
        <Img fixed={backgroundImgs.aboutUs.childImageSharp.fixed}/>
      ),
      subtitle: 'Kim jesteśmy',
      title: 'O firmie',
      text: <>
        Stanowimy doświadczony zespół, świadczący usługi z&nbsp;zakresu
        projektowania i produkcji materiałów POS. Od&nbsp;ponad 15 lat
        działalności dostarczamy kompleksowych rozwiązań odpowiadających
        zapotrzebowaniom klientów z&nbsp;wielu branż i&nbsp;sfer
        działalności.
        <br/>
        <br/>
        Każdy projekt traktujemy indywidualnie i jako nowe wyzwanie.
        Współpracujemy z klientem dzieląc się wiedzą i&nbsp;doświadczeniem
        a&nbsp;także wciąż podążając za nowymi trendami w rynku. Powierzone
        tematy obsługujemy od A do&nbsp;Z, zaczynając od etapu wizualizacji,
        przez produkcję aż po logistykę i wdrożenie w punktach sprzedaży.
      </>,
    },
    services: {
      hash: 'wsparcie-na-kazdym-etapie',
      title: 'Wsparcie na każdym etapie',
      text: <>
        Określone przez klientów cele realizujemy z&nbsp;pomocą zespołów
        kompetentnych pracowników o bogatej wiedzy i wieloletnim obyciu
        w&nbsp;branży reklamowej materiałów POS - od działu projektowego
        po produkcyjny. Wizualizujemy i&nbsp;konstruujemy, prototypujemy
        i&nbsp;produkujemy. Magazynujemy, dostarczamy i wstawiamy.
        Wszystko pod jednym dachem.
      </>,
      slides,
    },
    iconCards: {
      title: 'Dlaczego my?',
      cards,
    },
    testimonials: {
      title: 'Mówią o Nas',
      testimonials,
    },
  };
};
