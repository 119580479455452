import React from 'react';

import {useData} from '../data/who-are-we';
import WhoAreWe from '../sections/AboutCompany';

const Index = () => {
  const data = useData();

  return (
    <WhoAreWe data={data}/>
  );
};
export default Index;
